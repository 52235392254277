<mat-sidenav-container> 

    <mat-sidenav [opened]="false" mode="over" #sidenav class="sidenav mat-elevation-z6">
        <button mat-icon-button color="primary" class="close" (click)="sidenav.close()">
           <mat-icon color="primary">close</mat-icon>
        </button>
        <div class="divider"></div>
        <app-sidenav-menu [menuItems]="sidenavMenuItems" [menuParentId]="0"></app-sidenav-menu>
        <div class="divider"></div>
        <div class="py-1">
            <app-social-icons [iconSize]="'lg'" [iconColor]="'primary'"></app-social-icons>
            <!-- <svg class="social-icon" viewBox="0 0 24 24">
                <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M18,5H15.5A3.5,3.5 0 0,0 12,8.5V11H10V14H12V21H15V14H18V11H15V9A1,1 0 0,1 16,8H18V5Z" />
            </svg>
            <svg class="social-icon" viewBox="0 0 24 24">
                <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M17.71,9.33C18.19,8.93 18.75,8.45 19,7.92C18.59,8.13 18.1,8.26 17.56,8.33C18.06,7.97 18.47,7.5 18.68,6.86C18.16,7.14 17.63,7.38 16.97,7.5C15.42,5.63 11.71,7.15 12.37,9.95C9.76,9.79 8.17,8.61 6.85,7.16C6.1,8.38 6.75,10.23 7.64,10.74C7.18,10.71 6.83,10.57 6.5,10.41C6.54,11.95 7.39,12.69 8.58,13.09C8.22,13.16 7.82,13.18 7.44,13.12C7.81,14.19 8.58,14.86 9.9,15C9,15.76 7.34,16.29 6,16.08C7.15,16.81 8.46,17.39 10.28,17.31C14.69,17.11 17.64,13.95 17.71,9.33Z" />
            </svg>
            <svg class="social-icon" viewBox="0 0 24 24">
                <path d="M5,3H19A2,2 0 0,1 21,5V19A2,2 0 0,1 19,21H5A2,2 0 0,1 3,19V5A2,2 0 0,1 5,3M19.5,12H18V10.5H17V12H15.5V13H17V14.5H18V13H19.5V12M9.65,11.36V12.9H12.22C12.09,13.54 11.45,14.83 9.65,14.83C8.11,14.83 6.89,13.54 6.89,12C6.89,10.46 8.11,9.17 9.65,9.17C10.55,9.17 11.13,9.56 11.45,9.88L12.67,8.72C11.9,7.95 10.87,7.5 9.65,7.5C7.14,7.5 5.15,9.5 5.15,12C5.15,14.5 7.14,16.5 9.65,16.5C12.22,16.5 13.96,14.7 13.96,12.13C13.96,11.81 13.96,11.61 13.89,11.36H9.65Z" />
            </svg> -->
        </div> 
    </mat-sidenav>

    <mat-toolbar color="primary" id="header-toolbar">
        
            <mat-toolbar-row fxLayoutAlign="space-Fbtween center" class="top-toolbar theme-container" style="z-index: 1000; height: 64px;">
                <span fxHide="false" fxHide.gt-sm>
                    <button mat-button (click)="sidenav.toggle()" class="sidenav-toggle">
                        <mat-icon>menu</mat-icon>
                    </button> 
                </span>

                <span fxLayoutAlign="center center" style="z-index: 1000">

                    <a class="logo" routerLink="/" (click)="closeSubMenus()">
                        <img class="logo-img" src="assets/images/logo/wow30Logo.png">
                    </a>
                    
                </span>

                <div class="w-100"></div>

                <div fxHide="true" fxShow.gt-sm>

                    <div class="flex justify-content-center">
                        <img class="utils-ico-img" src="assets/images/utils/telefon.svg">
                        <span class="pl-1 pr-3"><a [href]="office?.officePhoneRef" style="color: inherit; letter-spacing: 1px; z-index: 1000; text-decoration: none;">{{office?.officePhone}}</a></span>

                        <img class="utils-ico-img" src="assets/images/utils/telefon.svg">
                        <span class="pl-1 pr-3"><a [href]="office?.officePhoneRef2" style="color: inherit; letter-spacing: 1px; z-index: 1000; text-decoration: none;">{{office?.officePhone2}}</a></span>

                        <img class="utils-ico-img" src="assets/images/utils/email.svg">
                        <span class="pl-1 pr-3"><a [href]="office?.officeMailRef" style="color: inherit; letter-spacing: 1px; text-decoration: none;">{{office?.officeMail}}</a></span>
                    </div>

                </div>

                <!-- <app-social-icons [iconSize]="'md'"></app-social-icons> -->
            
                <app-top-menu></app-top-menu>
            </mat-toolbar-row>
                
            <!-- <mat-toolbar-row class="logo-toolbar">

                <div fxLayoutAlign="space-between center" class="theme-container">
                            
                    <div fxFlex="20">
                        <a class="logo" routerLink="/" (click)="closeSubMenus()">
                            <img class="logo-img" src="assets/images/logo/wow30Logo.png">
                        </a>
                    </div>
                    
                    <div fxFlex="20" fxLayout="row" fxLayoutAlign="end center">   
                        <div fxLayoutAlign="center center" [@.disabled]="true">
                                    
                            <ng-container *ngIf="router.url !== '/checkout'; else checkout">

                                <div fxLayout="column" fxLayoutAlign="center center" fxShow="false" fxShow.gt-xs class="top-cart">
                                    <div *ngIf="appService.Data.totalCartCount > 0" 
                                        fxLayout="column" 
                                        class="send-order ml-1">
                                        <button *ngIf="router.url !== '/checkout'" mat-flat-button color="accent" [routerLink]="['/checkout']">
                                            Megrendelés elküldése
                                        </button>
                                        <span *ngIf="appService.Data.totalCartCount > 0" class="amount">
                                            <bdi>{{appService.Data.totalCartCount}} {{'PRODUCT' | translate}} - 
                                                <span>{{appService.Data.totalPrice | number : '.0-0' | currencySign: currencyId}}</span>
                                            </bdi>
                                        </span>
                                    </div>    
                                </div>

                            </ng-container>
                            <ng-template #checkout>
                                <span *ngIf="appService.Data.totalCartCount > 0 && !isMobile" class="amount" style="font-size: 14px;">
                                    <bdi>{{appService.Data.totalCartCount}} {{'PRODUCT' | translate}} - 
                                        <span>{{appService.Data.totalPrice | number : '.0-0' | currencySign: currencyId}}</span>
                                    </bdi>
                                </span>
                            </ng-template>
        
                            <mat-menu #cartMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="top-menu-dropdown cart-dropdown">
                                <ng-template matMenuContent>
                                    <span (mouseleave)="cartMenuTrigger?.closeMenu()" class="d-block">
                                        <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted">
                                            <b><span class="mat-step-text-label">{{appService.Data.totalCartCount}} {{'PRODUCT' | translate}}</span></b>
                                            <b><a [routerLink]="['/cart']" class="text-muted mat-step-text-label">{{'VIEW_CART' | translate}}</a></b>
                                        </div>
                                        <div class="divider mt-1"></div>
                                        <div *ngIf="appService.Data.totalCartCount == 0" class="py-1 text-muted text-center">{{'NO_ITEM_IN_CART' | translate}}</div>
                                        <mat-list>
                                            <mat-list-item *ngFor="let product of appService.Data.cartList">

                                                <div matLine *ngIf="product?.businessName; else noBusinessName">
                                                    <h3 matLine style="font-size: 13px;" class="uppercase font-normal">{{product?.businessName}}</h3>
                                                </div>
                                                <ng-template #noBusinessName>
                                                    <h3 matLine style="font-size: 13px;" class="uppercase font-normal">{{product?.name}}</h3>
                                                </ng-template>

                                                <p matLine class="text-muted">
                                                    <bdi>{{product.cartCount}} x {{product.newPrice | number : '.0-0' | currencySign: currencyId}}</bdi>
                                                </p>

                                                <img [src]="product | ProductImageResolver" alt="image">
                                                
                                                <button mat-icon-button color="warn" class="remove" (click)="remove(product);stopClickPropagate($event)">
                                                    <mat-icon class="mat-icon-sm">close</mat-icon>
                                                </button>
                                            </mat-list-item>
                                        </mat-list>
                                        <div *ngIf="appService.Data.cartList.length > 0" class="cart-dropdown-footer">
                                            <div class="divider mt-1"></div>
                                            <div fxLayout="row" fxLayoutAlign="space-between center" class="text-muted mt-1">
                                                <b>{{'TOTAL' | translate}}:</b>
                                                <b class="new-price">{{appService.Data.totalPrice | number : '.0-0' | currencySign: currencyId}}</b>
                                            </div>
                                            <div class="divider mt-1"></div>
                                            <div fxLayout="row" fxLayoutAlign="space-between center" class="mt-1">
                                                <button mat-mini-fab color="warn" (click)="clear();stopClickPropagate($event)">
                                                    <mat-icon>remove_shopping_cart</mat-icon>
                                                </button>
                                                <a [routerLink]="['/checkout']" mat-mini-fab color="primary">
                                                    <mat-icon>check</mat-icon>
                                                </a>
                                            </div>
                                        </div>
                                    </span>
                                </ng-template>
                            </mat-menu>
                        </div> 
        
                        <form fxLayoutAlign="center center" fxHide="false" fxHide.gt-sm [@.disabled]="true"> 
                        </form> 

                    </div> 
                </div>

            </mat-toolbar-row> -->

    </mat-toolbar> 

    <!-- <app-menu fxShow="false" fxShow.gt-sm></app-menu>   -->
    
    <div class="theme-container main"> 
        <!-- <app-breadcrumb></app-breadcrumb> -->
        <router-outlet></router-outlet>
    </div>

    <!-- <app-options></app-options> -->

    <app-footer></app-footer>

    <div *ngIf="showBackToTop" fxLayout="row" fxLayoutAlign="center center" class="back-to-top transition" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>


 </mat-sidenav-container>