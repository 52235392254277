import { AppService } from '../app.service';
import { Brand, Category, Product } from '../app.models';
import { combineLatest } from 'rxjs';
import { Component, OnInit, HostListener, ViewChild, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { map, filter } from 'rxjs/operators';
import { MenuItem } from '../shared/interfaces/menuitem.interface';
import { MediaChange, MediaObserver } from '@angular/flex-layout';
import { Office, OfficeService } from '../services/office.service';
import { ProductBasePage } from './base/product-base-page';
import { Router, NavigationEnd } from '@angular/router';
import { SearchParams } from '../shared/interfaces/search.params.interface';
import { Settings, AppSettings } from '../app.settings';
import { SidenavMenuService } from '../theme/components/sidenav-menu/sidenav-menu.service';
import * as appStateSelectors from '../redux/app.state';
import { ActionConfig } from '../config/application.config';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    providers: [SidenavMenuService]
})
export class PagesComponent extends ProductBasePage implements OnInit, OnDestroy {
    
    @ViewChild('sidenav', { static: true }) sidenav: any;

    category: Category;
    categories: Category[];
    isMobile = false;
    office: Office;
    searchForm: FormGroup;
    settings: Settings;
    showBackToTop: boolean = false;
    sidenavMenuItems: Array<MenuItem> = [];
    query: string;

    constructor(public appSettings: AppSettings,
        public appService: AppService,
        public fb: FormBuilder,
        public officeService: OfficeService,
        public mediaObserver: MediaObserver,
        public sidenavMenuService: SidenavMenuService,
        public router: Router,
        @Inject(PLATFORM_ID) private platformId: Object) {
        super();

        this.settings = this.appSettings.settings;
        this.office = officeService.office;
    }

    ngOnInit() {
        super.ngOnInit();

        this.searchForm = this.fb.group({
            search: new FormControl(''),
        });

        this.subscriptions.push(

            combineLatest([
                this.appState.select(appStateSelectors.brandsSelector).pipe(filter(value => !!value)),
                this.appState.select(appStateSelectors.categoriesSelector).pipe(filter(value => !!value)),
                this.appState.select(appStateSelectors.genericSearchQueryCategorySelector),
                this.appState.select(appStateSelectors.genericSearchQueryTextSelector),
            ]).pipe(
                map(([a$, b$, c$, d$]) => ({
                    brands: a$,
                    categories: b$,
                    categoryId: c$,
                    query: d$,
                }))
            ).subscribe((result: any) => {

                const brands = result.brands;
                const categories = result.categories;
                if (categories) {

                    this.categories = categories;

                    const categoryId = result.categoryId;
                    this.category = categoryId ? this.categories.find(c => c.id === categoryId) : this.categories[0];

                    const query = result.query;
                    this.searchForm.get('search').setValue(query);

                    this.sidenavMenuItems = [
                        this.mapperService.createMenuItem(0, 'NAV.HOME', 0, false, ['']),

                        this.mapperService.createMenuItem(1, 'Élmény és Élvezet',   0, false, ['/registration'], new Object({ category: 'unique-label' })),
                        this.mapperService.createMenuItem(2, 'Boraink',             0, false, ['/registration'], new Object({ category: 'our_wines' })),
                        this.mapperService.createMenuItem(3, 'Ajándékcsomagok',     0, false, ['/registration'], new Object({ category: 'surprise_pack' })),
                        this.mapperService.createMenuItem(4, 'VIP Klub',            0, false, ['/registration'], new Object({ category: 'vip' })),
                        this.mapperService.createMenuItem(5, 'Bórkóstolók',         0, false, ['/registration'], new Object({ category: 'tasting' })),
                        this.mapperService.createMenuItem(6, 'Privát borász',       0, false, ['/registration'], new Object({ category: 'private' })),
                        this.mapperService.createMenuItem(7, 'Bortúrák',            0, false, ['/registration'], new Object({ category: 'wine-trip' })),
                        this.mapperService.createMenuItem(8, 'Catering',            0, false, ['/registration'], new Object({ category: 'catering' })),
                    ];
                    
                    // if (categories?.length) {
                    //     const sidenavMenuCategories = categories ? categories.filter((c: Category) => c.id)
                    //         .map((c: Category) => this.mapperService.toCategoryMenuItem(c)) : null;
                    //     if (sidenavMenuCategories) {
                    //         sidenavMenuCategories.forEach((c: MenuItem) => this.sidenavMenuItems.push(c));
                    //     }
                    // }
                }
            }),

            this.mediaObserver.asObservable()
                .pipe(filter((changes: MediaChange[]) => changes.length > 0), map((changes: MediaChange[]) => changes[0]))
                .subscribe((change: MediaChange) => {
                    if (change.mqAlias == 'xs') {
                        this.isMobile = true;

                    } else {
                        this.isMobile = false;
                    }
                }),

            // this.searchForm.get('search')!.valueChanges.pipe(debounceTime(900), distinctUntilChanged()).subscribe((query: string) => {
            //     if (query) {
            //         this.search(query);
            //     }
            // }),    
        );        

        console.log(`-- Page init getCategories`);
        this.getCategories();

        console.log(`-- Page init getBrands`);
        this.getBrands();

        if (this.isCompanyAuthenticated) {
            console.log(`-- Page init getCompanyConfig`);
            this.getCompanyConfig();
        }

        //this.sidenavMenuItems = this.sidenavMenuService.getSidenavMenuItems();
        setTimeout(() => {
            this.settings.theme = 'green';
        });
    }    

    // public getCategories(){
    //   this.appService.getCategories().subscribe(data => {
    //     this.categories = data;
    //     this.category = data[0];
    //     this.appService.Data.categories = data;
    //   })
    // }
    
    private search(query: string) {

        this.query = query;
        const queryParams: SearchParams = { query };

        if (this.category.id) {
            Object.assign(queryParams, { category: this.category.code });
        }

        this.router.navigate(['/search']);
        setTimeout(() => {
            this.publishAction(ActionConfig.SET_GENERIC_SEARCH_QUERY_CATEGORY, {categoryCode: this.category.code, query});
        });
    }

    // public onChangeCategory(categoryChange: CategoryChange) {

    //     if (categoryChange?.categoryCode) {
    //         this.category = this.categories.find(category => category.code == categoryChange?.categoryCode);
    //     } else {
    //         this.category = this.categories.find(category => category.id === 0);
    //     }

    //     if (categoryChange?.event && window.innerWidth < 960) {
    //         this.stopClickPropagate(categoryChange.event);
    //     }

    //     this.search(this.query);
    // }

    public remove(product: Product) {
        const index: number = this.appService.Data.cartList.indexOf(product);
        if (index !== -1) {
            this.appService.Data.cartList.splice(index, 1);
            this.appService.Data.totalPrice = this.appService.Data.totalPrice - product.newPrice * product.cartCount;
            this.appService.Data.totalCartCount = this.appService.Data.totalCartCount - product.cartCount;
            this.appService.resetProductCartCount(product);
        }
    }

    public clear() {
        this.appService.Data.cartList.forEach(product => {
            this.appService.resetProductCartCount(product);
        });
        this.appService.Data.cartList.length = 0;
        this.appService.Data.totalPrice = 0;
        this.appService.Data.totalCartCount = 0;
    }

    public changeTheme(theme) {
        this.settings.theme = theme;
    }

    public stopClickPropagate(event: any) {
        event.stopPropagation();
        event.preventDefault();
    }

    public scrollToTop() {
        var scrollDuration = 200;
        var scrollStep = -window.pageYOffset / (scrollDuration / 20);
        var scrollInterval = setInterval(() => {
            if (window.pageYOffset != 0) {
                window.scrollBy(0, scrollStep);
            }
            else {
                clearInterval(scrollInterval);
            }
        }, 10);
        if (window.innerWidth <= 768) {
            setTimeout(() => {
                if (isPlatformBrowser(this.platformId)) {
                    window.scrollTo(0, 0);
                }
            });
        }
    }

    @HostListener('window:scroll', ['$event'])
    onWindowScroll($event) {
        const scrollTop = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop);
        let header_toolbar = document.getElementById('header-toolbar');
        if (header_toolbar) {
            if (scrollTop >= header_toolbar.clientHeight) {
                this.settings.mainToolbarFixed = true;
            }
            else {
                if (!document.documentElement.classList.contains('cdk-global-scrollblock')) {
                    this.settings.mainToolbarFixed = false;
                }
            }
        }
        else {
            this.settings.mainToolbarFixed = true;
        }
        ($event.target.documentElement.scrollTop > 300) ? this.showBackToTop = true : this.showBackToTop = false;
    }

    ngAfterViewInit() {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.sidenav.close();
            }
        });
        this.sidenavMenuService.expandActiveSubMenu(this.sidenavMenuService.getSidenavMenuItems());
    }

    closeSubMenus() {
        if (window.innerWidth < 960) {
            this.sidenavMenuService.closeAllSubMenus();
        }
    }

    onSearch($event: MouseEvent) {
        $event.preventDefault();

        const query = this.searchForm.get('search').value;
        this.search(query);
    }
}
