import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Office, OfficeService } from 'src/app/services/office.service';
import { SmartDialogTypeId } from 'src/app/config/application.config';
import { Router } from '@angular/router';

@Component({
    templateUrl: 'smart-dialog.html',
    styleUrls: ['./smart-dialog.scss']
})
export class SmartDialogComponent {

    smartDialogTypeId = SmartDialogTypeId;

    office: Office;
    dialogTypeId: number;
    subMessage: string;
    message: string;

    constructor(
        public dialogRef: MatDialogRef<SmartDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public officeService: OfficeService,
        public router: Router
        ) { 
            this.office = officeService.office;

            if (data) {
                this.dialogTypeId = data.dialogTypeId;
                this.message = data.message;
                this.subMessage = data.subMessage;
            }
        }

    onTickClick(): void {

        this.dialogRef.close();
    }
}
