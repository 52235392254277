import { AppService } from '../../../app.service';
import { AppSettings } from '../../../app.settings';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CompanyConfigDto } from 'src/api/gbcar/models';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import * as appStateSelectors from '../../../redux/app.state';

@Component({
    selector: 'app-top-menu',
    templateUrl: './top-menu.component.html'
})
export class TopMenuComponent implements OnInit, OnDestroy {

    companyConfig: CompanyConfigDto;
    isCompanyAuthenticated = false;

    private subscriptions: Array<Subscription> = new Array<Subscription>();

    constructor(
        public readonly appState: Store<appStateSelectors.IAppState>,
        public readonly appSettings: AppSettings,
        public readonly appService: AppService,
        public readonly authenticationService: AuthenticationService,
        public readonly translateService: TranslateService) {
    }

    ngOnInit() {

        this.subscriptions.push(
            this.appState.select(appStateSelectors.companyConfigSelector).subscribe(companyConfig => {
                this.companyConfig = companyConfig;

                this.isCompanyAuthenticated = this.authenticationService.isCompanyAuthenticated;
            }),
        );
    }

    ngOnDestroy() {

        if (this.subscriptions) {
            this.subscriptions.forEach(s => s.unsubscribe());
        }
    }

    onSignOutClick() {
        this.authenticationService.signOut();
    }
}
