<div class="top-menu">    

    <!-- <a mat-button [matMenuTriggerFor]="currencyMenu" #currencyMenuTrigger="matMenuTrigger">
        {{currency}}<mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #currencyMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
        <span (mouseleave)="currencyMenuTrigger.closeMenu()">
            <button mat-menu-item *ngFor="let cur of currencies" (click)="changeCurrency(cur)">
                <span>{{cur}}</span>
            </button>
        </span>
    </mat-menu>-->

<!-- <span fxShow="false" fxShow.gt-sm>
    <a mat-button routerLink="/compare">{{'COMPARE' | translate }} <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.compareList.length}})</a>
    <a mat-button routerLink="/wishlist">{{'WISHLIST' | translate}} <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.wishList.length}})</a>
</span>-->

  <div class="flex align-items-center">

    <!-- <a class="flex align-items-center mr-1" 
       href="https://www.facebook.com/profile.php?id=100088113224851" 
       target="blank">

      <img class="utils-ico-img" src="assets/images/utils/facebook.svg" width="21px"  height="21px">
    </a>
    
    <a class="flex align-items-center mr-4"
       href="https://www.instagram.com/glanz_brothers/" 
       target="blank">

      <img class="utils-ico-img" src="assets/images/utils/instagram.svg" width="21px" height="21px">
    </a> -->
    
    <a [style.visibility]="isCompanyAuthenticated && companyConfig ? 'visible' : 'hidden'" 
       [style.width]="isCompanyAuthenticated && companyConfig ? '100%' : '0px'" 
       mat-button 
       #userMenuTrigger="matMenuTrigger"
       [matMenuTriggerFor]="userMenu">

        <mat-icon class="login-icon">person</mat-icon>
        <span fxShow="false" fxShow.gt-sm style="font-family: Roboto, sans-serif; font-weight: 600;">
            {{companyConfig?.businessUser?.lastName}} {{companyConfig?.businessUser?.firstName}}
        </span>
        <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>       
    </a>

    <mat-menu #userMenu="matMenu" 
              [overlapTrigger]="false" 
              xPosition="before" 
              class="app-menu-panel user-menu">
        <span (mouseleave)="userMenuTrigger.closeMenu()">

            <!-- /account/profile -->
            <a mat-menu-item [routerLink]="['/account']">
                <mat-icon class="mat-icon-sm">person</mat-icon>
                <span>Profil</span>
            </a>

            <div class="divider"></div>

            <a mat-menu-item [routerLink]="['/']"
                             (click)="onSignOutClick()">
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>Kijelentkezés</span>
            </a>            
    
        </span>
    </mat-menu>

  </div>

    <!-- <a mat-button [matMenuTriggerFor]="langMenu" #langMenuTrigger="matMenuTrigger"> 
        <img [src]="'assets/images/flags/'+translateService.currentLang+'.svg'" width="18" [alt]="translateService.currentLang">   
        <span fxShow="false" fxShow.gt-sm class="flag-menu-title">{{getLangText(translateService.currentLang)}}</span>
        <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #langMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown">
        <span (mouseleave)="langMenuTrigger.closeMenu()"> 
            <button *ngFor="let lang of translateService.getLangs()" mat-menu-item (click)="changeLang(lang)">  
                <img [src]="'assets/images/flags/'+lang+'.svg'" width="18" [alt]="lang" class="mat-elevation-z1"> 
                <span class="px-1">{{getLangText(lang)}}</span>   
            </button> 
        </span>
    </mat-menu> -->

    <!-- <a mat-button [matMenuTriggerFor]="accountMenu" #accountMenuTrigger="matMenuTrigger">
        <mat-icon class="mat-icon-sm account-icon">person</mat-icon> 
        <span fxShow="false" fxShow.gt-sm>{{ 'ACCOUNT' | translate }}</span> 
        <mat-icon class="mat-icon-sm caret">arrow_drop_down</mat-icon>
    </a>
    <mat-menu #accountMenu="matMenu" [overlapTrigger]="false" xPosition="before" class="app-dropdown account">
        <span (mouseleave)="accountMenuTrigger.closeMenu()">
            <div fxLayout="row" fxLayoutAlign="space-around center" class="user-info">                   
                <img src="assets/images/others/user.jpg" alt="user-image" width="50">
                <p>Emilio Verdines <br> <small>emilio_v <span *ngIf="settings.rtl">&rlm;</span>(152<mat-icon class="mat-icon-xs">star</mat-icon>)</small></p>
            </div>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/account"> 
                <mat-icon class="mat-icon-sm">settings</mat-icon>
                <span>Account Settings</span> 
            </a>
            <a mat-menu-item routerLink="/compare" fxHide="false" fxHide.gt-sm>
                <mat-icon class="mat-icon-sm">compare</mat-icon>
                <span>Compare <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.compareList.length}})</span> 
            </a>
            <a mat-menu-item routerLink="/wishlist" fxHide="false" fxHide.gt-sm>
                <mat-icon class="mat-icon-sm">favorite</mat-icon>
                <span>Wishlist <span *ngIf="settings.rtl">&rlm;</span>({{appService.Data.wishList.length}})</span>
            </a>
            <a mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">lock</mat-icon>
                <span>Lock screen</span> 
            </a>
            <a mat-menu-item routerLink="/"> 
                <mat-icon class="mat-icon-sm">help</mat-icon>
                <span>Help</span> 
            </a>
            <div class="divider"></div>
            <a mat-menu-item routerLink="/sign-in"> 
                <mat-icon class="mat-icon-sm">power_settings_new</mat-icon>
                <span>{{ 'LOGOUT' | translate }}</span> 
            </a>
        </span>
    </mat-menu> -->

</div>  