
<div class="top-banner-container" fxLayout="column">

    <div class="banner-back-container">
        <div class="banner-title-container">
            <h2 class="title">A borok csodálatos világa</h2>
            <!-- <h2 class="title2">MESTERFOKON</h2> -->
        </div>    
    </div>
    
</div>


<div class="banners-container" fxLayout="column" *ngIf="banners.length > 0">

    <div fxLayout="column" fxLayout.gt-sm="row" class="padding-bottom-fix">

        <div fxLayout="row" fxFlex="50" class="margin-right">

            <div class="banner margin-right-fix" style="background: rgb(143 143 143);" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'unique-label'}">

                <div class="info info1 unique-label" style="opacity: .6;" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                    </div> 
                </div>

                <div class="banner-title-container">
                    <div class="title title-shadow cleaning" style="color: white">&nbsp;&nbsp;Élmény és Élvezet&nbsp;&nbsp;</div>
                </div>

            </div>                

            <div class="banner" style="background: rgb(139 139 139);" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'our_wines'}">

                <div class="info info1 our_wines" style="opacity: .6;" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">
                    </div> 
                </div> 

                <div class="banner-title-container">
                    <div class="title title-shadow care" style="color: white">&nbsp;&nbsp;Boraink&nbsp;&nbsp;</div>
                </div>

            </div>
        </div>

        <div fxLayout="row" fxFlex="50" class="padding-top" >
            <div class="banner margin-right-fix" style="background: rgb(175 170 151)" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'surprise_pack'}">

                <div class="info info1 surprise_pack" style="opacity: .6;" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">

                    </div> 
                </div> 

                <div class="banner-title-container">
                    <div class="title title-shadow protection">&nbsp;&nbsp;Ajándékcsomagok&nbsp;&nbsp;</div>
                </div>

            </div>
            
            <div class="banner" style="background: rgb(137 137 137)" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'vip'}">

                <div class="info info1 vip" style="opacity: .6;" fxFlex fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">

                    </div> 
                </div> 

                <div class="banner-title-container">
                    <div class="title title-shadow accessories">&nbsp;&nbsp;VIP Klub&nbsp;&nbsp;</div>
                </div>
    
            </div>

        </div>

    </div>

    <div fxLayout="column" fxLayout.gt-sm="row" class="padding-top-fix">

        <div fxLayout="row" fxFlex="50" class="margin-right">
            <div class="banner info1 margin-right-fix" style="background: rgb(141 141 141)" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'tasting'}">

                <div class="info tasting" style="opacity: .6;" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">

                    </div> 
                </div> 

                <div class="banner-title-container">
                    <div class="title title-shadow rim-cleaning">&nbsp;&nbsp;Bórkóstolók&nbsp;&nbsp;</div>
                </div>

            </div>

            <div class="banner info1" style="background: rgb(175 175 175);" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'private'}">

                <div class="info private" style="opacity: .6;" fxFlex fxLayoutAlign="center center">

                </div> 

                <div class="banner-title-container">
                    <div class="title title-shadow polishing-material">&nbsp;&nbsp;Privát borász&nbsp;&nbsp;</div>
                </div>
    
            </div>

        </div>


        <div fxLayout="row" fxFlex="50" class="padding-bottom">

            <div class="banner margin-right-fix" style="background: rgb(193 193 179);;" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'wine-trip'}">

                <div class="info info1 wine-trip" style="opacity: .6;" fxLayoutAlign="center center">
                    <div class="m-1" fxLayout="column" fxLayoutAlign="end center">

                    </div> 
                </div> 

                <div class="banner-title-container">
                    <div class="title title-shadow waxes">&nbsp;&nbsp;Bortúrák&nbsp;&nbsp;</div>
                </div>

            </div>

            <div class="banner" style="background: rgb(185 181 167);" fxLayout="column" fxFlex="50" 
                 [routerLink]="['/registration']" 
                 [queryParams]="{category: 'catering'}">

                <div class="info info1 catering" style="opacity: .6;" fxFlex fxLayoutAlign="center center">

                </div> 

                <div class="banner-title-container">
                    <div class="title title-shadow air-fresheners">&nbsp;&nbsp;Catering&nbsp;&nbsp;</div>
                </div>

            </div>
        </div>

    </div>

</div>
