/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetShopProductResponse } from '../models/get-shop-product-response';
import { GetShopProductLinksResponse } from '../models/get-shop-product-links-response';
import { GetShopProductsResponse } from '../models/get-shop-products-response';
import { PageableRequest } from '../models/pageable-request';
import { GetProductGroupTypesResponse } from '../models/get-product-group-types-response';
@Injectable({
  providedIn: 'root',
})
class ShopProductService extends __BaseService {
  static readonly getApiShopProductPath = '/api/ShopProduct';
  static readonly getApiShopProductLinksPath = '/api/ShopProduct/Links';
  static readonly postApiShopProductListPath = '/api/ShopProduct/List';
  static readonly getApiShopProductGroupTypesPath = '/api/ShopProduct/GroupTypes';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShopProductService.GetApiShopProductParams` containing the following parameters:
   *
   * - `productId`:
   *
   * - `currencyId`:
   *
   * @return Success
   */
  getApiShopProductResponse(params: ShopProductService.GetApiShopProductParams): __Observable<__StrictHttpResponse<GetShopProductResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productId != null) __params = __params.set('productId', params.productId.toString());
    if (params.currencyId != null) __params = __params.set('currencyId', params.currencyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ShopProduct`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductResponse>;
      })
    );
  }
  /**
   * @param params The `ShopProductService.GetApiShopProductParams` containing the following parameters:
   *
   * - `productId`:
   *
   * - `currencyId`:
   *
   * @return Success
   */
  getApiShopProduct(params: ShopProductService.GetApiShopProductParams): __Observable<GetShopProductResponse> {
    return this.getApiShopProductResponse(params).pipe(
      __map(_r => _r.body as GetShopProductResponse)
    );
  }

  /**
   * @param params The `ShopProductService.GetApiShopProductLinksParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `currencyId`:
   *
   * @return Success
   */
  getApiShopProductLinksResponse(params: ShopProductService.GetApiShopProductLinksParams): __Observable<__StrictHttpResponse<GetShopProductLinksResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.currencyId != null) __params = __params.set('currencyId', params.currencyId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ShopProduct/Links`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductLinksResponse>;
      })
    );
  }
  /**
   * @param params The `ShopProductService.GetApiShopProductLinksParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `currencyId`:
   *
   * @return Success
   */
  getApiShopProductLinks(params: ShopProductService.GetApiShopProductLinksParams): __Observable<GetShopProductLinksResponse> {
    return this.getApiShopProductLinksResponse(params).pipe(
      __map(_r => _r.body as GetShopProductLinksResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiShopProductListResponse(body?: PageableRequest): __Observable<__StrictHttpResponse<GetShopProductsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ShopProduct/List`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductsResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiShopProductList(body?: PageableRequest): __Observable<GetShopProductsResponse> {
    return this.postApiShopProductListResponse(body).pipe(
      __map(_r => _r.body as GetShopProductsResponse)
    );
  }

  /**
   * @return Success
   */
  getApiShopProductGroupTypesResponse(): __Observable<__StrictHttpResponse<GetProductGroupTypesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ShopProduct/GroupTypes`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductGroupTypesResponse>;
      })
    );
  }
  /**
   * @return Success
   */
  getApiShopProductGroupTypes(): __Observable<GetProductGroupTypesResponse> {
    return this.getApiShopProductGroupTypesResponse().pipe(
      __map(_r => _r.body as GetProductGroupTypesResponse)
    );
  }
}

module ShopProductService {

  /**
   * Parameters for getApiShopProduct
   */
  export interface GetApiShopProductParams {
    productId?: string;
    currencyId?: number;
  }

  /**
   * Parameters for getApiShopProductLinks
   */
  export interface GetApiShopProductLinksParams {
    id?: number;
    currencyId?: number;
  }
}

export { ShopProductService }
