import { Injectable } from '@angular/core';
import { Brand, Category, Product, ProductImage } from '../app.models';
import { ProductBrandDto, ProductGroupTypeDto, ProductImageDto, ShopProductDto } from 'src/api/gbcar/models';
import { MenuItem } from '../shared/interfaces/menuitem.interface';
import { NavigationExtras } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class MapperService {

    toProductImage(dto: ProductImageDto): ProductImage {

        let result: ProductImage;

        if (dto) {

            result = {
                id: dto.id,
                imageUrl: dto.imageUrl,
                isDefault: dto.isDefault,
                order: dto.order,
                productPublicId: dto.productPublicId,
                thumbnailImageUrl: dto.thumbnailImageUrl,
            };
        }

        return result;
    }

    toProduct(dto: ShopProductDto): Product {

        let result: Product;

        if (dto) {

            result = {
                id: dto.id,
                productId: dto.productId,
                productBrandCode: dto.productBrandCode,
                name: dto.productName,
                businessName: dto.productBusinessName,
                nameCode: dto.productNameCode,
                image: dto.imageUrl,
                productImages: dto.productImages?.length ? dto.productImages.map(pi => this.toProductImage(pi)).sort((ac1, ac2) => (ac1.order > ac2.order) ? 1 : ((ac2.order > ac1.order) ? -1 : 0)) : null,
                oldPrice: 0,
                newPrice: dto.price,
                discount: 0,
                ratingsCount: 0,
                ratingsValue: 0,
                description: dto.productDescription,
                availibilityCount: 6,
                cartCount: 0,
                color: [],
                size: [],
                weight: 0,
                categoryId: dto.productGroupTypeId,
                currencyId: dto.currencyId,
            };
        }

        return result;
    }

    toBrand(dto: ProductBrandDto): Brand {

        let result: Brand;

        if (dto) {
            result = new Brand(dto);
        }

        return result;
    }

    toCategory(dto: ProductGroupTypeDto): Category {

        let result: Category;

        if (dto) {

            result = {
                id: dto.id,
                name: dto.name,
                code: dto.code,
                hasSubCategory: dto.parentProductGroupTypeId ? true : false,
                parentId: dto.parentProductGroupTypeId ? dto.parentProductGroupTypeId : 0,
            };
        }

        return result;
    }

    toBrandMenuItem(brand: Brand, parentId: number, hasSubMenu: boolean): MenuItem {
        let result: MenuItem;
        if (brand) {
            result = this.createMenuItem(brand.id+parentId, brand.name, parentId, hasSubMenu, ['/products'], new Object({ filterBrands: brand.code }));
        }
        return result;
    }

    toCategoryMenuItem(category: Category): MenuItem {
        let result: MenuItem;
        if (category) {
            result = this.createMenuItem(category.id, category.name, 0, false, ['/products'], new Object({ filterCategories: category.code }));
        }
        return result;
    }

    createMenuItem(id: number, title: string, parentId: number, hasSubMenu: boolean, routerLink: Array<any> = null, queryParams: NavigationExtras = null): MenuItem {
        return {
            id,
            parentId: parentId,
            title,
            order: 0,
            hasSubMenu: hasSubMenu,
            routerLink,
            queryParams
        } as MenuItem;
    }
}
