/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { GbcarApiConfiguration, GbcarApiConfigurationInterface } from './gbcar-api-configuration';

import { AddressService } from './services/address.service';
import { CompanyService } from './services/company.service';
import { CompanyOrderService } from './services/company-order.service';
import { ConfigurationService } from './services/configuration.service';
import { ExternalAuthenticationService } from './services/external-authentication.service';
import { ExternalOrderService } from './services/external-order.service';
import { ExternalSimplePayService } from './services/external-simple-pay.service';
import { ExternalUtilityService } from './services/external-utility.service';
import { ExternalVisitorService } from './services/external-visitor.service';
import { ExternalWineVisitorService } from './services/external-wine-visitor.service';
import { ProductService } from './services/product.service';
import { ProductBrandService } from './services/product-brand.service';
import { ProductImageService } from './services/product-image.service';
import { ProductOrderService } from './services/product-order.service';
import { ShopConfigService } from './services/shop-config.service';
import { ShopConfigCompanyService } from './services/shop-config-company.service';
import { ShopProductService } from './services/shop-product.service';
import { ShopProductBrandService } from './services/shop-product-brand.service';
import { ShopProductCompanyService } from './services/shop-product-company.service';
import { SystemRoleService } from './services/system-role.service';
import { SystemRoleModuleAccessRightService } from './services/system-role-module-access-right.service';
import { SystemUserService } from './services/system-user.service';
import { VenueService } from './services/venue.service';
import { VisitorService } from './services/visitor.service';
import { WineVisitorService } from './services/wine-visitor.service';

/**
 * Provider for all gbcar-api services, plus GbcarApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    GbcarApiConfiguration,
    AddressService,
    CompanyService,
    CompanyOrderService,
    ConfigurationService,
    ExternalAuthenticationService,
    ExternalOrderService,
    ExternalSimplePayService,
    ExternalUtilityService,
    ExternalVisitorService,
    ExternalWineVisitorService,
    ProductService,
    ProductBrandService,
    ProductImageService,
    ProductOrderService,
    ShopConfigService,
    ShopConfigCompanyService,
    ShopProductService,
    ShopProductBrandService,
    ShopProductCompanyService,
    SystemRoleService,
    SystemRoleModuleAccessRightService,
    SystemUserService,
    VenueService,
    VisitorService,
    WineVisitorService
  ],
})
export class GbcarApiModule {
  static forRoot(customParams: GbcarApiConfigurationInterface): ModuleWithProviders<GbcarApiModule> {
    return {
      ngModule: GbcarApiModule,
      providers: [
        {
          provide: GbcarApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
