import { Injectable } from '@angular/core';

export class GpsCoord {
    constructor(public lat: number,
                public lng: number) { }
}

export class Office {

    constructor(public gpsCoord: GpsCoord,
                public zoom: number,
                public bankAccountNumber: string,
                public bankName: string,
                public company: string,
                public companyRegistration: string,
                public officeCity: string,
                public officeZip: string,
                public officeAddress: string,

                public officePhoneRef: string,
                public officePhone: string,

                public officePhoneRef2: string,
                public officePhone2: string,

                public officePhoneRef3: string,
                public officePhone3: string,

                public officeMailRef: string,
                public officeMail: string,
                public supportMailRef: string,
                public supportMail: string,
                public tax: string,
                public version: string,
                public web: string) { }
}

@Injectable({
    providedIn: 'root',
})
export class OfficeService {

    public office = {
        gpsCoord: {
            lat: 47.61190756773414,
            lng: 19.117476166608803,
        },
        zoom: 13,

        bankAccountNumber: '10403538-50527074-51481009',
        bankName: 'K & H Bank',
        company: `Glanz Brother’s`,
        companyRegistration: '57735595',

        officeCity: 'Dunakeszi',
        officeZip: '2120',
        officeAddress: 'Pallag u 13.',

        officePhoneRef: 'tel:+36706158648',
        officePhone: '(+36) 70 615-8648',

        officePhoneRef2: 'tel:+204381787',
        officePhone2: '(+36) 20 438-1787',

        officePhoneRef3: 'tel:+36703989213',
        officePhone3: '(+36) 70 398-9213',

        officeMailRef: 'mailto:wow30@wow30.hu',
        officeMail: 'wow30@wow30.hu',

        supportMailRef: 'mailto:konczol_l@hotmail.com',
        supportMail: 'konczol_l@hotmail.com',

        tax: '59687065233',

        version: '1.2',

        web: 'www.wow30.hu'
    };
}
