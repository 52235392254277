import { SidenavMenu } from './sidenav-menu.model';

export const sidenavMenuItems = [ 
    // new SidenavMenu (1, 'NAV.HOME', '/', null, null, false, 0),
    // new SidenavMenu (60, 'NAV.PAGES', null, null, null, true, 0),   
    // new SidenavMenu (61, 'NAV.SHOP', null, null, null, true, 60), 
    // new SidenavMenu (63, 'NAV.PRODUCT_DETAIL', '/products/2/PC All-in-One', null, null, false, 61), 
    // new SidenavMenu (64, 'NAV.CART', '/cart', null, null, false, 61), 
    // new SidenavMenu (65, 'NAV.CHECKOUT', '/checkout', null, null, false, 61),  
    // new SidenavMenu (70, 'LOGIN', '/sign-in', null, null, false, 60), 
    // new SidenavMenu (71, 'NAV.404_PAGE', '/404', null, null, false, 60), 
    // new SidenavMenu (72, 'NAV.LANDING', '/landing', null, null, false, 60), 
    // new SidenavMenu (80, 'NAV.CONTACT', '/contact', null, null, false, 0),  
    // new SidenavMenu (90, 'NAV.ADMIN', '/admin', null, null, false, 0),  
]
