/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ImportResponse } from '../models/import-response';
import { ProductDto } from '../models/product-dto';
import { GetProductsResponse } from '../models/get-products-response';
import { PageableRequest } from '../models/pageable-request';
import { GetProductImagesResponse } from '../models/get-product-images-response';
@Injectable({
  providedIn: 'root',
})
class ProductService extends __BaseService {
  static readonly postApiProductImportPath = '/api/Product/Import';
  static readonly postApiProductImportAvatarImagePath = '/api/Product/ImportAvatarImage';
  static readonly postApiProductRemoveAvatarImagePath = '/api/Product/RemoveAvatarImage';
  static readonly postApiProductImportImagePath = '/api/Product/ImportImage';
  static readonly postApiProductListPath = '/api/Product/List';
  static readonly getApiProductLinksPath = '/api/Product/Links';
  static readonly getApiProductImagesPath = '/api/Product/Images';
  static readonly patchApiProductUpdatePath = '/api/Product/Update';
  static readonly patchApiProductSetProductBestsellersPath = '/api/Product/SetProductBestsellers';
  static readonly patchApiProductSetProductNewsPath = '/api/Product/SetProductNews';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ProductService.PostApiProductImportParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductImportResponse(params: ProductService.PostApiProductImportParams): __Observable<__StrictHttpResponse<ImportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.File != null) { __formData.append('File', params.File as string | Blob);}
    if (params.ProductPublicId != null) { __formData.append('ProductPublicId', params.ProductPublicId as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Product/Import`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportResponse>;
      })
    );
  }
  /**
   * @param params The `ProductService.PostApiProductImportParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductImport(params: ProductService.PostApiProductImportParams): __Observable<ImportResponse> {
    return this.postApiProductImportResponse(params).pipe(
      __map(_r => _r.body as ImportResponse)
    );
  }

  /**
   * @param params The `ProductService.PostApiProductImportAvatarImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductImportAvatarImageResponse(params: ProductService.PostApiProductImportAvatarImageParams): __Observable<__StrictHttpResponse<ProductDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.File != null) { __formData.append('File', params.File as string | Blob);}
    if (params.ProductPublicId != null) { __formData.append('ProductPublicId', params.ProductPublicId as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Product/ImportAvatarImage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductDto>;
      })
    );
  }
  /**
   * @param params The `ProductService.PostApiProductImportAvatarImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductImportAvatarImage(params: ProductService.PostApiProductImportAvatarImageParams): __Observable<ProductDto> {
    return this.postApiProductImportAvatarImageResponse(params).pipe(
      __map(_r => _r.body as ProductDto)
    );
  }

  /**
   * @param params The `ProductService.PostApiProductRemoveAvatarImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductRemoveAvatarImageResponse(params: ProductService.PostApiProductRemoveAvatarImageParams): __Observable<__StrictHttpResponse<ProductDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.File != null) { __formData.append('File', params.File as string | Blob);}
    if (params.ProductPublicId != null) { __formData.append('ProductPublicId', params.ProductPublicId as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Product/RemoveAvatarImage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductDto>;
      })
    );
  }
  /**
   * @param params The `ProductService.PostApiProductRemoveAvatarImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductRemoveAvatarImage(params: ProductService.PostApiProductRemoveAvatarImageParams): __Observable<ProductDto> {
    return this.postApiProductRemoveAvatarImageResponse(params).pipe(
      __map(_r => _r.body as ProductDto)
    );
  }

  /**
   * @param params The `ProductService.PostApiProductImportImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductImportImageResponse(params: ProductService.PostApiProductImportImageParams): __Observable<__StrictHttpResponse<ImportResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let __formData = new FormData();
    __body = __formData;
    if (params.File != null) { __formData.append('File', params.File as string | Blob);}
    if (params.ProductPublicId != null) { __formData.append('ProductPublicId', params.ProductPublicId as string | Blob);}
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Product/ImportImage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ImportResponse>;
      })
    );
  }
  /**
   * @param params The `ProductService.PostApiProductImportImageParams` containing the following parameters:
   *
   * - `File`:
   *
   * - `ProductPublicId`:
   *
   * @return Success
   */
  postApiProductImportImage(params: ProductService.PostApiProductImportImageParams): __Observable<ImportResponse> {
    return this.postApiProductImportImageResponse(params).pipe(
      __map(_r => _r.body as ImportResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiProductListResponse(body?: PageableRequest): __Observable<__StrictHttpResponse<GetProductsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/Product/List`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiProductList(body?: PageableRequest): __Observable<GetProductsResponse> {
    return this.postApiProductListResponse(body).pipe(
      __map(_r => _r.body as GetProductsResponse)
    );
  }

  /**
   * @param productId undefined
   * @return Success
   */
  getApiProductLinksResponse(productId?: number): __Observable<__StrictHttpResponse<GetProductsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (productId != null) __params = __params.set('productId', productId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Product/Links`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductsResponse>;
      })
    );
  }
  /**
   * @param productId undefined
   * @return Success
   */
  getApiProductLinks(productId?: number): __Observable<GetProductsResponse> {
    return this.getApiProductLinksResponse(productId).pipe(
      __map(_r => _r.body as GetProductsResponse)
    );
  }

  /**
   * @param productId undefined
   * @return Success
   */
  getApiProductImagesResponse(productId?: string): __Observable<__StrictHttpResponse<GetProductImagesResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (productId != null) __params = __params.set('productId', productId.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/Product/Images`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetProductImagesResponse>;
      })
    );
  }
  /**
   * @param productId undefined
   * @return Success
   */
  getApiProductImages(productId?: string): __Observable<GetProductImagesResponse> {
    return this.getApiProductImagesResponse(productId).pipe(
      __map(_r => _r.body as GetProductImagesResponse)
    );
  }

  /**
   * @param body undefined
   */
  patchApiProductUpdateResponse(body?: ProductDto): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Product/Update`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param body undefined
   */
  patchApiProductUpdate(body?: ProductDto): __Observable<null> {
    return this.patchApiProductUpdateResponse(body).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  patchApiProductSetProductBestsellersResponse(body?: Array<number>): __Observable<__StrictHttpResponse<ProductDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Product/SetProductBestsellers`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  patchApiProductSetProductBestsellers(body?: Array<number>): __Observable<ProductDto> {
    return this.patchApiProductSetProductBestsellersResponse(body).pipe(
      __map(_r => _r.body as ProductDto)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  patchApiProductSetProductNewsResponse(body?: Array<number>): __Observable<__StrictHttpResponse<ProductDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'PATCH',
      this.rootUrl + `/api/Product/SetProductNews`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ProductDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  patchApiProductSetProductNews(body?: Array<number>): __Observable<ProductDto> {
    return this.patchApiProductSetProductNewsResponse(body).pipe(
      __map(_r => _r.body as ProductDto)
    );
  }
}

module ProductService {

  /**
   * Parameters for postApiProductImport
   */
  export interface PostApiProductImportParams {
    File?: Blob;
    ProductPublicId?: string;
  }

  /**
   * Parameters for postApiProductImportAvatarImage
   */
  export interface PostApiProductImportAvatarImageParams {
    File?: Blob;
    ProductPublicId?: string;
  }

  /**
   * Parameters for postApiProductRemoveAvatarImage
   */
  export interface PostApiProductRemoveAvatarImageParams {
    File?: Blob;
    ProductPublicId?: string;
  }

  /**
   * Parameters for postApiProductImportImage
   */
  export interface PostApiProductImportImageParams {
    File?: Blob;
    ProductPublicId?: string;
  }
}

export { ProductService }
