<footer class="bg-primary footer">

    <!-- <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="center center" class="subscribe-block-container mat-elevation-z3">
    
        <div fxLayout="column" fxLayout.gt-sm="row wrap" fxLayoutAlign="space-between center" class="subscribe-block theme-container">
            <div style="visibility: hidden;" fxLayout="column" fxFlex.gt-sm="50" fxLayoutAlign.gt-sm="center start" class="subscribe-title">
                <h1 class="fw-500">{{'SUBSCRIBE_NEWSLETTER' | translate}}</h1>
                <h3 class="fw-400 secondary-color">{{'STAY_UP_TO_DATE_WITH_LATEST_PRODUCTS' | translate}}</h3>
            </div>
            <form style="visibility: hidden;" method="get" class="subscribe-search-form" fxFlex.gt-sm="50">
                <input type="text" placeholder="E-mail cím..." fxFlex>

                <div class="subscribe-button-container">
                    <button mat-raised-button color="accent" (click)="subscribe()" type="button" class="subscribe-button mat-elevation-z0 text-muted">Feliratkozás</button>
                </div>
            </form>
        </div>

    </div> -->

    <div class="link-block">
        <div fxLayout="row wrap" fxLayoutAlign="space-between" class="pb-4 theme-container">

            <div fxFlex="100" fxFlex.gt-md="20" fxFlex.gt-sm="33.3"            
                              ngClass.sm="mt-2" ngClass.xs="mt-2"
                              fxShow="false" fxShow.gt-sm> 

                <a class="logo" routerLink="/">
                    <img class="logo-img" width="100%" src="assets/images/logo/wow30Logo.png">
                </a>

                <div class="ml-4">
               <!-- <p class="mt-1"><a routerLink="/conditions/transport" class="link secondary-color">Kézbesítés és szállítás</a></p>
               <p class="mt-1"><a routerLink="/conditions/how-to-buy" class="link secondary-color">Vásárlás módja</a></p>
               <p class="mt-1"><a routerLink="/conditions/product-change" class="link secondary-color">Termékcsere és visszaküldés</a></p>
               <p class="mt-1"><a routerLink="/conditions/complaint" class="link secondary-color">Reklamáció</a></p> -->
               <p class="mt-1"><a routerLink="/conditions/general-conditions" class="link secondary-color">Általános szerződési feltételek</a></p>
               <!-- <p class="mt-1"><a routerLink="/conditions/personal-data-protection" class="link secondary-color">A személyes adatok védelme</a></p> -->
                </div>

            </div>

            <div fxFlex="100" fxFlex.gt-md="19" fxFlex.gt-sm="33.3" 
                 ngClass.sm="mt-4" ngClass.xs="mt-2" 
                 ngClass.gt-xs="pr-2"> 
                <h3 class="col-title">{{'CONTACT_INFORMATION' | translate}}</h3>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <img class="utils-ico-img" src="assets/images/utils/location.svg">
                    <span>{{office?.officeCity}}, {{office?.officeAddress}}</span>
                </p>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <img class="utils-ico-img" src="assets/images/utils/telefon.svg">
                    <span><a [href]="office?.officePhoneRef" style="color: inherit; letter-spacing: 1px; text-decoration: none;">{{office?.officePhone}}</a></span>
                </p>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <img style="visibility: hidden;" class="utils-ico-img" src="assets/images/utils/telefon.svg">
                    <span><a [href]="office?.officePhoneRef2" style="color: inherit; letter-spacing: 1px; text-decoration: none;">{{office?.officePhone2}}</a></span>
                </p>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <img style="visibility: hidden;" class="utils-ico-img" src="assets/images/utils/telefon.svg">
                    <span><a [href]="office?.officePhoneRef3" style="color: inherit; letter-spacing: 1px; text-decoration: none;">{{office?.officePhone3}}</a></span>
                </p>

                <p fxLayout="row" fxLayoutAlign="start center" class="mt-1 secondary-color">
                    <img class="utils-ico-img" src="assets/images/utils/email.svg">
                    <span><a [href]="office?.officeMailRef" style="color: inherit; letter-spacing: 1px; text-decoration: none;">{{office?.officeMail}}</a></span>
                </p>

                <div fxLayout="row" class="mt-1 secondary-color">
                    <table>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>

                        <tr>
                            <td style="display: flex;">
                                <img class="utils-ico-img-time" src="assets/images/utils/time.svg">
                            <td><span>Hétfő - Péntek</span></td>
                            <td style="text-align: end;"><span class="ml-1">9:00 - 17:00</span></td>
                        </tr>

                    </table>
                </div>
            </div>

            <p class="mt-2" fxHide="false" fxHide.gt-sm><a routerLink="/conditions/general-conditions" class="link secondary-color">Általános szerződési feltételek</a></p>

            <!-- <div fxFlex="100" fxFlex.gt-md="23" fxFlex.gt-sm="50" 
                 ngClass.md="mt-2" ngClass.sm="mt-2" ngClass.xs="mt-2"
                 style="display: flex; align-items: center; justify-content: center;">

                 <div ngClass.gt-md="image-shop-md" ngClass="image-shop-sm">
                 </div>
                 
            </div> -->

            <div fxFlex="100" fxFlex.gt-md="28" fxFlex.gt-sm="100"
                              ngClass.md="mt-2" 
                              ngClass.sm="mt-2" 
                              ngClass.xs="mt-2">
                <agm-map [latitude]="lat" 
                         [longitude]="lng" 
                         [zoom]="zoom" 
                         [scrollwheel]="!isMobile"
                         [mapTypeControl]="true"
                         [fullscreenControl]="true"
                         [keyboardShortcuts]="false"
                         [styles]="appService?.mapStyles">
                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
                </agm-map>
            </div>

        </div>

        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" class="copyright secondary-color theme-container">
            <p ngClass.xs="mt-1">Copyright © {{thisYear}} All Rights Reserved {{version}}</p>
            <!-- <p>Designed & Developed by <a mat-button href="http://themeseason.com/" target="_blank">ThemeSeason</a></p> -->
        </div>
    </div>

</footer>