/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { GbcarApiConfiguration as __Configuration } from '../gbcar-api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GetShopProductResponse } from '../models/get-shop-product-response';
import { GetShopProductLinksResponse } from '../models/get-shop-product-links-response';
import { GetShopProductsResponse } from '../models/get-shop-products-response';
import { GetShopProductsCompanyRequest } from '../models/get-shop-products-company-request';
@Injectable({
  providedIn: 'root',
})
class ShopProductCompanyService extends __BaseService {
  static readonly getApiShopProductCompanyPath = '/api/ShopProductCompany';
  static readonly getApiShopProductCompanyLinksPath = '/api/ShopProductCompany/Links';
  static readonly postApiShopProductCompanyListPath = '/api/ShopProductCompany/List';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `ShopProductCompanyService.GetApiShopProductCompanyParams` containing the following parameters:
   *
   * - `productId`:
   *
   * - `currencyId`:
   *
   * - `companyUserEmail`:
   *
   * @return Success
   */
  getApiShopProductCompanyResponse(params: ShopProductCompanyService.GetApiShopProductCompanyParams): __Observable<__StrictHttpResponse<GetShopProductResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.productId != null) __params = __params.set('productId', params.productId.toString());
    if (params.currencyId != null) __params = __params.set('currencyId', params.currencyId.toString());
    if (params.companyUserEmail != null) __params = __params.set('companyUserEmail', params.companyUserEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ShopProductCompany`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductResponse>;
      })
    );
  }
  /**
   * @param params The `ShopProductCompanyService.GetApiShopProductCompanyParams` containing the following parameters:
   *
   * - `productId`:
   *
   * - `currencyId`:
   *
   * - `companyUserEmail`:
   *
   * @return Success
   */
  getApiShopProductCompany(params: ShopProductCompanyService.GetApiShopProductCompanyParams): __Observable<GetShopProductResponse> {
    return this.getApiShopProductCompanyResponse(params).pipe(
      __map(_r => _r.body as GetShopProductResponse)
    );
  }

  /**
   * @param params The `ShopProductCompanyService.GetApiShopProductCompanyLinksParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `currencyId`:
   *
   * - `companyUserEmail`:
   *
   * @return Success
   */
  getApiShopProductCompanyLinksResponse(params: ShopProductCompanyService.GetApiShopProductCompanyLinksParams): __Observable<__StrictHttpResponse<GetShopProductLinksResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.id != null) __params = __params.set('id', params.id.toString());
    if (params.currencyId != null) __params = __params.set('currencyId', params.currencyId.toString());
    if (params.companyUserEmail != null) __params = __params.set('companyUserEmail', params.companyUserEmail.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/ShopProductCompany/Links`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductLinksResponse>;
      })
    );
  }
  /**
   * @param params The `ShopProductCompanyService.GetApiShopProductCompanyLinksParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `currencyId`:
   *
   * - `companyUserEmail`:
   *
   * @return Success
   */
  getApiShopProductCompanyLinks(params: ShopProductCompanyService.GetApiShopProductCompanyLinksParams): __Observable<GetShopProductLinksResponse> {
    return this.getApiShopProductCompanyLinksResponse(params).pipe(
      __map(_r => _r.body as GetShopProductLinksResponse)
    );
  }

  /**
   * @param body undefined
   * @return Success
   */
  postApiShopProductCompanyListResponse(body?: GetShopProductsCompanyRequest): __Observable<__StrictHttpResponse<GetShopProductsResponse>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/ShopProductCompany/List`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GetShopProductsResponse>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Success
   */
  postApiShopProductCompanyList(body?: GetShopProductsCompanyRequest): __Observable<GetShopProductsResponse> {
    return this.postApiShopProductCompanyListResponse(body).pipe(
      __map(_r => _r.body as GetShopProductsResponse)
    );
  }
}

module ShopProductCompanyService {

  /**
   * Parameters for getApiShopProductCompany
   */
  export interface GetApiShopProductCompanyParams {
    productId?: string;
    currencyId?: number;
    companyUserEmail?: string;
  }

  /**
   * Parameters for getApiShopProductCompanyLinks
   */
  export interface GetApiShopProductCompanyLinksParams {
    id?: number;
    currencyId?: number;
    companyUserEmail?: string;
  }
}

export { ShopProductCompanyService }
